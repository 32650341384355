<template>
    <div class="right-side">
        <h2>Порядок торгов</h2>
        <beat-loader 
            v-if="loading" 
            class="loader-center" 
            :color="'#553DB4'" 
            :loading="loading" 
            :size="20" 
            :sizeUnit="'px'"
        />
        <div class="faqs" v-else>
            <trade-accordion
                v-for="(item) in items"
                :key="item.id"
                :item="item"
            >
            </trade-accordion>
        </div>
    </div>
</template>

<script>

import TradeAccordion from '../../components/ui/TradeAccordion.vue'

export default {
    name: 'inform-trading',
    components: {
        TradeAccordion
    },
    data: () => ({
        items: null,
        loading: true
    }),
    mounted() {
        this.getItems()
    },
    methods: {
        getItems() {
            let params = {
                entity: "MarketplaceRuleDescription",
                length: 100,
                page: 0,
                sort: [
                    {
                        field: 'sort',
                        by: 'asc'
                    }
                ]
            }

            this.$store.dispatch('GET_LIST', params)   
                .then((res) => {
                    this.loading = true
                    this.items = res.data.content
                    this.loading = false
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.faqs {
    margin-top: 30px !important;
}
</style>