<template>
    <div class="faq">
        <div class="faq-title" @click="openItem">
            <button v-if="item.details" class="btn btn__purple">{{ open ? '-' : '+' }}</button>
            <button v-if="desc" class="btn btn__purple">{{ open ? '-' : '+' }}</button>
            <template v-if="item.marketplaceRu">
            {{ item.marketplaceRu }}
            </template>
            <template v-else-if="item.system">
            {{ item.system.nameRu }}
            </template>
            <template v-else>
            {{item.nameRu}}
            </template>
        </div>
        <div class="faq-content" v-if="open">
            <trade-accordion 
                v-for="child in children"
                :key="child.id"
                :item="child"
                :desc="child"
            />
            <div v-if="desc">
                {{desc.nameRu}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'tradeAccordion',
        props: ['item', 'desc'],
        data: () => ({
            open: false
        }),
        computed: {
            children() {
                return this.item.details && this.item.details.filter(i => i != null)
            }
        },
        methods: {
            openItem() {
                this.open = !this.open
            }
        }
    }
</script>

<style lang="scss" scoped>
.faq {
    border-bottom: 1px solid #eee;
    padding: 10px;
    cursor: pointer;
}

.faq-content{
    padding: 10px 15px 15px 30px ;
    background: #fff;
    margin-top: 10px;
}

.btn__purple{
    background: #553DB4;
    color: #fff;
    min-width: 35px;
    outline: none;
    margin-right: 10px;
}
</style>